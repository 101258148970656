//Mobile styles for nav bar


/* ==========================================
   Header MOBILE
   ======================================= */


.slide-contain {
  text-align: center;
  .mobile-category-thumbnail {
    border-right: 1px solid $black;
  }
  .industry-mobile-thumb {
    border: none;
  }
}
.prod-mobile-thumb,
.industry-mobile-thumb,
.service-mobile-thumb {
  
}

.products-we-offer .category-thumbnail {
  min-height: auto;
}

.prod-mobile-desc,
.industry-mobile-desc,
.service-mobile-desc {
  font-size: 0.9em;
  font-family: "Merriweather Sans",sans-serif;
  font-weight: bold;
}
.mobile-header {
  background-color: $dark-grey;
  margin-bottom: 20px;
  color: $white;
  padding: 0.5625rem 0.9375rem;
  font-size: 1.2em;
  font-family: "Merriweather Sans",sans-serif;
  text-transform: uppercase;
}
.mobile-nav-contain {
  background: $white;
  display: block;
  float: left;
  width: 100%;
  /*padding: 5px 0;*/
  position: relative;
  z-index: 999;
  .column {
    padding: 0;
  }
}
.mobile-drop-bar {
  display: block;
  float: left;
  width: 33%;
  padding: 0.9375rem;
}
.mobile-bars {
  color: $black !important;
  font-size: 30px !important;
}
.mobile-viatran {
  display: block;
  float: left;
  width: 33%;
  text-align: center;
}
.mobile-viatran a > img {
  max-width: 160px;
  width: 30%;
  min-width: 80px; 
}
.mobile-search-cart {
  display: block;
  float: left;
  width: 33%;
  padding: 0.9375rem 0;
}
.mobile-search-cart .mobile-nav-link > img {
  max-height: 30px;
}
.mobile-nav-link {
  float: right;
  padding-right: 10px;
  /*width: 33%;*/
}
.mobile-dropdown {
  padding-left: 20px;
}
.mobile-nav-dropdowns > ul,
.mobile-search-cart > ul {
  margin: 0;
}
.dropdown-list > li {
  list-style: none;
  display: inline-block;
  padding: 5px 0 5px 10px;
  width: 100%;
}

.mobile-search-field {
  display: block;
  float: left;
  width: 100%;
  padding-top: 10px;
}
.mobile-search {
  color: #000;
  text-align: left;
  cursor: pointer;
  display: block;
  float: left;
  width: 80%;
  margin-left: 10px;
  border: none;
  border-bottom: solid 1px #ccc;
}

.products-we-offer .slick-dots {
  position: relative;
  margin-top: 10px;
  margin-bottom: 0px;
}

.mobile-category-title {
  padding: 0 0.625rem
}

//Custom breakpoint... no easy or obvious way to make it look as I wanted it to look.
@media screen and (max-width: 68em) {
  .slide .row {
   /* padding-left: 0.9375rem;
    padding-right: 0.9375rem;*/
  }
}

@media only screen and (min-width: 40.063em) { 

}


//Mobile styles needing media query
//@media screen and (max-width: 850px){
@media screen and (max-width: 850px) {
  
  .mobile-nav-contain.navopen {
    overflow: hidden;
    height: 100%;
    position:fixed;
    background-color: $light-grey;
  }

  .top-header {
    position: relative;
  }

  .mobile-nav-fixed {
    position: fixed;
    width: 100%;
    background-color: $white;
    padding: 5px 0 0;
    z-index: 5;
    border-bottom: 1px solid $black;
  }
  .mobile-nav-dropdowns {
      padding-top: 66px;
    &.isactive {
      position: fixed;
      overflow: scroll;
      height: 100%;
      width: 100%;
      -webkit-overflow-scrolling:touch;
    }
  }

  #page-contents, #page-contents.sticky-nav {
    padding-top: 0px;
  }

  .viatran-logo {
    bottom: 5px;
  }
  .top-bar {
    height: auto;
  }
  .nav-item {
    width: 100%;
  }
  .nav-icon {
    width: 100%;
  }
  .products-we-offer {
    padding-top: 0;
  }
  
  .product-page {
    .prod-desc {
      padding-left: 0.9375rem;
      /*border-left: none;*/
    }
  }
  .product-page {
    padding: 0 10px;
  }

.product-page .print-page {
    padding-left: 0;
    padding-top: 20px;
  }
  .banner {
    min-height: 0;
    /*padding-top: 71px;*/
    //height of top nav
  }
  .subpage-banner {
    /*padding-top: 71px; */
    //height of top nav
    margin-bottom: 0;
  }
  .product-page {
    /*margin-top: 71px; */
    //height of top nav
  }
  // .vertical {
  //   display: none; //hide subitems from menu in mobile
  // }

  /* ==========================================
   Dropdown Menu Mobile
   ======================================= */

  .mobile-nav-dropdowns li.nav-item.toplevel {
    text-transform: uppercase;
    display: block;
    float: none;
    padding: 0; 
    position:relative;   
    li {
      font-size: 0.8rem;
      padding: 0;
      line-height: 1;
      display: block;
      float: none;
    }
    a {
      display: block;
      text-align: left;
      padding: 0.875rem 0.9375rem;
      color: $dark-grey;
      border-bottom: $light-mid-grey 1px solid;
      background-color: $light-mid-grey;
    }
  }

  .mobile-nav-dropdowns li.nav-item.toplevel ul > li > a {
    background-color: $light-grey;
    line-height: 1rem;
  }

  .mobile-nav-dropdowns ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  /* ==========================================
   Contact / Login / Contact Boxes
   ======================================= */

  .mobile-dropdown-box .hoverbox {
    padding: 0.9375rem;
    background-color: $light-grey;


    &.advanced-search-hoverbox input#keyword {
      width: 82%;
    }
    &.advanced-search-hoverbox input#keyword-submit {
      width: 18%;
      height: 2.4375rem;
      border: 1px solid #2d3895;
    }
    h5 {
      color: $dark-grey;
    }
    .closebtn {
      position: absolute;
      bottom: 0.9375rem;
      left: 0.9375rem;
    }
  }



}
@media screen and (max-width: 400px) {
  .slide-contain {
    /*border-right: none;*/
  }
  /*.about-us-section {
    padding-left: 10px;
  }*/
  .bottom-footer-row p {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 640px) {

  h1 {
    font-size: 1.5rem;
  }
  .banner h3 {
    font-size: 1rem; 
  }
  .mobile-nav-dropdowns {
      padding-top: 50px;
  }

  .mobile-viatran a > img {
    min-width: 60px;
  }

  .mobile-drop-bar {
    padding-top: 0.5625rem;
    padding-bottom: 0.5625rem;
  }

  .mobile-search-cart {
    padding: 0.5625rem 0; 
  }

  .mobile-search-cart .mobile-nav-link>img {
    max-height: 25px;
  }

  .mobile-bars {
    font-size: 26px !important;
  }

  //Set column padding on custom elements to match foundation columns
  .mobile-header, .mobile-drop-bar {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  //Remove min height for mobile
  .banner .slide.slick-slide {
    min-height: 0;
    padding-bottom: 50px;
  }
  .banner .viatran-slider {
    min-height: 0;
  }

  .grid-view .row .row {
    position:inherit;
    border:none;
  }

  .grid-view .row .row .column.medium-4 {
    position: relative;
    padding-bottom: 45px;
    border-bottom: 1px solid $dark-grey;

    & .product-cta {
      bottom:10px;
    }
  }

  .product-page {
    .prod-desc {
      padding-left: 0;
      border-left: none;
    }
  }

  .list-view h2 {
    font-size: 0.9em;
  }
  .list-view h3 {
    font-size: 0.8em;
  }
  .grid-view h2 {
    font-size: 1em;
  }
  .grid-view h3 {
    font-size: 0.9em;
  }

  .resources-page .resources-right {
    margin-top: 2rem;
  }

  .resources-page .tabs-panel .columns {
    padding: 0;
  }
  .resources-vert-tabs {
    
  }

}