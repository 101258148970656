//Nothing in mobile at the moment.

@media screen and (max-width: 992px){


}


@media screen and (max-width: 650px){

    .viatran-logo {
        bottom: 0;

    }
}