.product-page {
    .column {
        padding: 0;
    }

    .row .row {
        margin: 0;
    }

    margin-top: 25px;

    .prod-image {
        text-align: center;
        img {
            width: 100%;
        }
    }

    .cart-cta {
     color: #fff;
     /*background: #2d3895 url("/static/img/shopping-cart-icon-white.png") no-repeat;
     background-position: 15px 15px;*/
     background-color: $dark-grey;
     width: auto;
     display: inline-block;
     padding: 10px 20px;
     font-size: 1.5em;
 }

.features-container, .resources-container {
    display: block;
    float: left;
    padding: 1.25rem 0 0;
    & .additional-downloads {
        padding-top: 1.25rem;
    }
}


    .prod-desc {

        padding-left: 1.25rem;
        border-left: 1px solid $dark-grey;

        h2 {
            color: $soft-blue;
            text-transform: uppercase;
            font-weight: bold;
            font-family: $spartan;
        }

        h3 {
            color: $black;
        }

        p {
            color: $black;
        }


    }

        .slick-dots {
        bottom: -30px;
        margin-bottom: 0;
    }

    }

    .data-sheet-contain {
        padding-top: 20px;
        border-top: 2px solid $light-grey;

    //Isotope grid
    //Done "not mobile first", couldn't think of a good way to make it be mobile first.
    .grid-sizer, .grid-item { width: 32%; margin-right: 10px; margin-bottom: 10px; background-color: $light-grey; }
    .grid-item--width2 { width: 65%; }
    .grid-item--width3 { width: 98%; }

    @media screen and (max-width: 850px){
            .grid-sizer, .grid-item { height: auto !important; width: 100% !important; }
    }


.data-category {
    height: auto !important;

    .data-category-content {
      display: none;
    }

    &.expanded .data-category-content {
      display: block;
    }

    .fa-chevron-down {
        display: none;
    }

    &.expanded .fa-chevron-down {
        display: inline;
    }

    &.expanded .fa-chevron-right {
        display: none;
    }

}

.data-category-title {
    background-color: $dark-grey;
    color: $white;
    padding: 10px 0 10px 10px;}

    .data-category-content {
        padding: 20px 10px 10px 10px;
    }

    .data-row-title {
        font-size: .8rem;
        width: 50%;
        float: left;
        clear: both;
    }

    .data-row-desc {
      font-size: .8rem;
      padding-bottom: 15px;
      width: 50%;
      float: right;
      clear: both;
    }

    .data-heading {
        font-weight: bold;
        width: 50%;
        float: left;
    }

    .data-details {
        display: inline-block;
        width: 50%;
        float: left;
    }

    .data-row-title + .data-row-desc {
    clear: none;
}

    .blueprint-image {
        padding-top: 20px;
    }

    .print-page { 
      padding-left: 25px;
    }

}
