.products-we-offer {
    .category {
        position: relative;
        z-index: 20;
        overflow: hidden;
    }

    .overlay-wrapper:hover .category-title {
        background: $light-grey;
        opacity:0.9;
        color: $dark-grey;
        padding-top:10px;
    }

    .industry-grid .overlay-wrapper:hover .category-title {
       background: $moderate-yellow;
       color: $dark-grey;
       opacity:0.9;
    }

    .industry-grid .overlay:hover .category-title {
      background: $moderate-yellow;
    }



    .category:hover .vertical-align {
        transform: 0;
    }

    .category:hover .overlay {
        display: block;
    }




    .overlay {
        position: absolute;
        z-index: 25;
        opacity:.9;
        /*background: $soft-blue;*/

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a2d1e5+0,4bbaea+100 */
        background: $grey; /* Old browsers */
        background: -moz-linear-gradient(top, $light-grey 0%, $grey 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, $light-grey 0%,$grey 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, $light-grey 0%,$grey 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cccccc', endColorstr='#f4f4f4',GradientType=0 ); /* IE6-9 */

        width: 100%;
        // padding: 3rem;
        text-align: center;
        span {
            color: $dark-grey;
            font-size: 18px;
            font-family: $merri;
        }
        hr {
            display: block;
            height: 1px;
            border: 0;
            border-top: 1px solid $dark-grey;
            margin: 0 0 5px 0;
        }
        p {
            color: $dark-grey;
        }
    }


    .industry-grid .overlay {
        background: $moderate-yellow;
    }

    .overlay
    .overlay-contain {
        padding: 0 2rem 0 2rem;
    }

    .overlay-wrapper {
        position: relative;
        width: 100%;
    }

    .overlay-wrapper.active .category-title {
        font-family: $spartan;
    }
}
